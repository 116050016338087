module services {
    export module manifest {
        export class manifestService implements interfaces.manifest.IManifestService{
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            getManifestList(): ng.resource.IResourceClass<interfaces.manifest.IManifestDisplay> {
                return this.$resource<interfaces.manifest.IManifestDisplay>(this.ENV.DSP_URL + "Manifest/GetManifests", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }

            requestEDIResponseResend(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/RequestEDIResponseResend", {
                    ownerEntityId: "@ownerEntityId",
                    manifestId: "@manifestId"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getManifestListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Manifest/GetManifestsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;
                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getDropdown(ownerEntityId?: number, searchText?: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Manifest/GetDropdown", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText
                });
            }


            getVersionCancelReasons(ownerEntityId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Manifest/GetVersionCancelReasons", {
                    ownerEntityId: ownerEntityId
                });
            }

            create(ownerEntityId: number, manifestTypeId: number, counterId: number, customNumber: string, route: interfaces.applicationcore.IDropdownModel, manifest: interfaces.applicationcore.IDropdownModel): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/Create", {
                    ownerEntityId: ownerEntityId,
                    manifestTypeId: manifestTypeId,
                    counterId: counterId,
                    customNumber: customNumber,
                    routeId: route ? route.Id : 0,
                    manifestId: manifest ? manifest.Id : undefined
                });
            }

            getDefaults(entityId?: number, routeId?: number, manifestId?: number): ng.resource.IResourceClass<interfaces.manifest.IManifestDefaults> {
                return this.$resource<interfaces.manifest.IManifestDefaults>(this.ENV.DSP_URL + "Manifest/GetDefaults", {
                    entityId: entityId,
                    routeId: routeId,
                    manifestId: manifestId
                });
            }

            get(manId: number): ng.resource.IResourceClass<interfaces.manifest.IManifest> {
                return this.$resource<interfaces.manifest.IManifest>(this.ENV.DSP_URL + "Manifest/Get", {
                    manId: manId
                }, {
                    get: {
                        method: "GET",
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                    }
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/Save");
            }

            getStatusHistory(manId: number): ng.resource.IResourceClass<interfaces.manifest.IManifestStatusHistory> {
                return this.$resource<interfaces.manifest.IManifestStatusHistory>(this.ENV.DSP_URL + "Manifest/GetStatusHistory", {
                    manId: manId
                }, {
                    query: {
                        method: "GET",
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                    }
                });
            }

            validateAndDownloadASYCUDAXml(manifestId: number, countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IStringResult | interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IStringResult | interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/ValidateAndDownloadASYCUDAXml", {
                    manifestId: manifestId,
                    countryId: countryId
                });
            }

            saveAndValidateEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/SaveAndValidateEDI");
            }

            saveAndSendEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/SaveAndSendEDI");
            }

            getManifestsForCombiningById(manId: number): ng.resource.IResourceClass<interfaces.manifest.ICombineManifestLookUp> {
                return this.$resource<interfaces.manifest.ICombineManifestLookUp>(this.ENV.DSP_URL + "Manifest/GetManifestsForCombiningById", {
                    manId: manId
                }, {
                        query: {
                            method: "GET",
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            combineMaifestsProcessing(manId: number, selectedId: Array<number>): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CombineMaifestsProcessing", {
                    manId: manId,
                    selectedId: selectedId
                });
            }

            generateManifestSundryItem(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/GenerateManifestSundryItem");
            }

            apportionManifestSundryItemsToConsignments(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/ApportionManifestSundryItemsToConsignments");
            }

            requestClearing(manId: number, automateClearing: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/RequestClearing", {
                    manId: manId,
                    automateClearing: automateClearing
                });
            }

            cIAcknowledged(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CIAcknowledged", {
                    manId: manId
                });
            }

            updateManifestReadyforDispatch(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/UpdateManifestReadyforDispatch", {
                    manId: manId
                });
            }

            updateManifestDispatched(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/UpdateManifestDispatched", {
                    manId: manId
                });
            }

            updateManifestArrived(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/UpdateManifestArrived", {
                    manId: manId
                });
            }

            updateManifestDelivered(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/UpdateManifestDelivered", {
                    manId: manId
                });
            }

            cancelManifest(manId: number, reason: string, changeReasonId?:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CancelManifest", {
                    manId: manId,
                    reason: reason,
                    changeReasonId: changeReasonId
                });
            }

            getManifestCloseDetails(manId: number): ng.resource.IResourceClass<interfaces.manifest.IManifestClose> {
                return this.$resource<interfaces.manifest.IManifestClose>(this.ENV.DSP_URL + "Manifest/GetManifestCloseDetails", {
                    manId: manId
                }, {
                        get: {
                            method: "GET",
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            updateManifestClose(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/UpdateManifestClose");
            }

            closeManifest(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CloseManifest", {
                    manId: manId
                });
            }

            getManifestRights(): ng.resource.IResourceClass<number[]> {
                return this.$resource<number[]>(this.ENV.DSP_URL + "Manifest/GetManifestRights");
            }

            ediGenAllGoodsDecForManifest(manifestId: number, functionClassificationValueId?: number, versionChangeReason?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/EdiGenAllGoodsDecForManifest", {
                    manifestId: manifestId,
                    functionClassificationValueId: functionClassificationValueId,
                    versionChangeReason: versionChangeReason
                });
            }

            eDIValidateForManifest(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/EDIValidateForManifest", {
                    manId: manId
                });
            }

            eDISendForManifest(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/EDISendForManifest", {
                    manId: manId
                });
            }

            sendforExternalClearing(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/SendforExternalClearing", {
                    manId: manId
                });
            }

            getGoodsDeclarationForGenerateManifestResult(manId: number, isAccepted: boolean): ng.resource.IResourceClass<ng.resource.IResource<{ value: boolean }>> {
                return this.$resource<ng.resource.IResource<{ value: boolean }>>(this.ENV.DSP_URL + "Manifest/GetGoodsDeclarationForGenerateManifestResult", {
                    manId: manId,
                    isAccepted: isAccepted
                });
            }

            createNewManifestVersion(manId: number, reason: string, changeReasonId?:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CreateNewManifestVersion", {
                    manId: manId,
                    reason: reason,
                    changeReasonId: changeReasonId
                });
            }

            GetManifestCustomsSummary(manId: number): ng.resource.IResourceClass<interfaces.manifest.IManifestCustomsSummary> {
                return this.$resource<interfaces.manifest.IManifestCustomsSummary>(this.ENV.DSP_URL + "Manifest/GetManifestCustomsSummary", {
                    manId: manId
                }, {
                        query: {
                            method: "GET",
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            CalculateALCVariance(manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Manifest/CalculateALCVariance", {
                    manId: manId
                });
            }
        }
    }
    angular.module("app").service("manifestService", services.manifest.manifestService);
}